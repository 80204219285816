<template>
    <div class="all-height d-flex justify-center">
        <div class="all-width">
            <v-card class="all-height all-width shadow-off" loader-height="2" :loading="loading" :disabled="loading">
                <v-card-text v-if="isFirst">
                    <div>
                        <v-row>
                            <v-col class="text-h1 text-center">
                                <div class="text-h5">Let us get started on setting up a close cycle</div>
                            </v-col>
                        </v-row>
                        <br>
                        <div class="text-h6 text-center font-weight-bold text-decoration-underline">
                            <v-btn small color="black " class="white--text" height="4vh"
                                @click="$nova.gotoLink({ path: '/financialclose/project/trialbalance/' + id })">
                                <span>Get Started</span>
                            </v-btn>
                        </div>
                    </div>
                </v-card-text>
                <v-card-text v-else>


                    <div class="d-flex align-center justify-space-between ">
                        <div class="">
                            <div class="d-flex">
                                <p class="mb-1 cursor-pointer" @click="$nova.gotoLink({ path: `/financialclose/project/dashboard/`+id })">Home</p>
                                <p class="px-2 mb-1">/</p>
                                <p class="mb-1">{{ metadata.description }}</p>
                            </div>
                            <div class="d-flex align-center justify-start">
                                <p class="black--text font-weight-bold text-h5 ma-0">Dashboard</p>
                                
                                    <v-tooltip bottom content-class="tooltip-bottom" >
                                        <template v-slot:activator="{ on, attrs }"> 
                                            <v-btn 
                                                v-bind="attrs"
                                                v-on="on"
                                                class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                                                size="24">mdi-refresh</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Refresh</span>
                                      </v-tooltip>
                                      
                                <lb-activitysidemenuNew :config="activitylogconfig"
                                    :url="`/v2/financialclose/project/getlogs/${metadata._id}`" />
                            </div>
                        </div>
                        
                    </div>



                    <div class="d-flex align-center justify-space-between mb-2" v-if="metadata._id">
                        
                        <div class=""></div>

                        <div class="border-on d-flex shadow-on radius-small">
                            <div class="text-center px-4 py-2 hoverable">
                                <div class="grey--text text--darken-1 line-1">{{ $store.state.LocationHeader ?
                                    $store.state.LocationHeader :'Location'}}</div>
                                <div class="font-weight-bold">
                                    <v-menu offset-y open-on-hover v-if="LocationsList?.length > 1">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">
                                                {{ ((selectedUsers && selectedUsers?.name) ?? 'All') }}
                                                <v-icon class="">mdi-chevron-down</v-icon>
                                            </div>
                                        </template>
                                        <perfect-scrollbar style="max-height: 300px">
                                            <v-list dense class="pa-0 border-left-default">
                                                <template v-for="(v, k) in LocationsList">
                                                    <v-list-item @click="filterWithLocation(v)" :key="k">
                                                        <v-list-item-title>{{ v.name || 'NA' }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </perfect-scrollbar>
                                    </v-menu>
                                    <span v-else>NA</span>
                                </div>
                            </div>
                            <div class="text-center px-4 py-2 hoverable">
                                <div class="grey--text text--darken-1 line-1">Users</div>
                                <div class="font-weight-bold">
                                    <v-menu offset-y open-on-hover v-if="userlist?.length > 1">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="filter-hide cursor-pointer" v-bind="attrs" v-on="on">
                                                {{ ((selectedLocation && selectedLocation?.name) ?? 'All') }}
                                                <v-icon class="">mdi-chevron-down</v-icon>
                                            </div>
                                        </template>
                                        <perfect-scrollbar style="max-height: 300px">
                                            <v-list dense class="pa-0 border-left-default">
                                                <template v-for="(v, k) in userlist">
                                                    <v-list-item :key="k">
                                                        <v-list-item-title>{{ getNameById(v) }}</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </perfect-scrollbar>
                                    </v-menu>
                                    <span v-else>NA</span>
                                </div>
                            </div>
                            <div>
                                <lb-divider type="vertical"></lb-divider>
                            </div>
                            <div class="text-center py-2 px-1 hoverable">
                                <div>
                                    <v-tooltip bottom content-class="tooltip-bottom">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="resetFilter()">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Reset filters</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-center justify-center mb-2">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="mr-2 mb-2" v-bind="attrs" v-on="on">mdi-information</v-icon>
                                </template>
                                <span>Count And Value Tooltip</span>
                            </v-tooltip>
                            <span
                                :class="`mr-3 ${typetoggle ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Count</span>
                            <v-switch inset dense class="mt-0" color="default" v-model="typetoggle" label=""
                                hide-details></v-switch>
                            <span
                                :class="`ml-1 ${!typetoggle ? 'grey--text text--lighten-1' : ''} font-weight-bold`">Value</span>

                        </div>

                    </div>
                    <div v-if="loading && !metadata._id">
                        <v-skeleton-loader
                            type="table-heading, list-item-two-line, article, article"></v-skeleton-loader>
                    </div>
                    <div v-if="metadata._id">
                        <v-row>
                            <v-col cols="6">
                                <div class="mb-2 ">
                                    <div class="d-flex align-center mb-1">
                                        <div class="display-1 mr-2">
                                            {{ (summary.checklist?.total?.[type] || 0) }}%
                                        </div>
                                        <div class="">
                                            <div class="body-1 line-1-2">Tasklist</div>
                                            <div class="line-1-2">Overall Progress</div>
                                        </div>
                                    </div>
                                    <v-progress-linear :value="((summary.checklist?.total?.[type] || 0)) || 0.5"
                                        color="green" height="12"></v-progress-linear>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <div class="mb-2">
                                    <div class="d-flex align-center mb-1">
                                        <div class="display-1 mr-2">
                                            {{ (summary.pbc?.total?.[type] || 0) }}%
                                        </div>
                                        <div class="">
                                            <div class="body-1 line-1-2">Information Requests</div>
                                            <div class="line-1-2">Overall Progress</div>
                                        </div>
                                    </div>
                                    <v-progress-linear :value="((summary.pbc?.total?.[type] || 0)) || 0.5"
                                        color="black lighten-1" height="12"></v-progress-linear>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div>
                                    <span class="font-weight-bold mr-1">Note:</span>
                                    <span>The components depicted below are based on the Chart of Accounts and the Trial
                                        Balance uploaded.
                                        In case there is no linkage or no trial balance is uploaded then all of the
                                        metrics would get
                                        categorised as part of Others.</span>
                                </div>
                            </v-col>

                            <template v-for="(v, k) in keytypes">
                                <v-col cols="2" :key="k"
                                    v-if="(selected === k || !selected) && (summary.checklist?.[k]?.category_detail || []).length > 0">
                                    <div :class="`pa-4 radius-on text-center cursor-pointer ${(selected === k) ? '' : 'hoverable'}`"
                                        @click="selected = k">
                                        <div class="subtitle-2 font-weight-bold mb-2">{{ v.name }}</div>
                                        <v-progress-circular :rotate="270" :size="120" :width="22"
                                            :value="((summary.checklist?.[k]?.[type] || 0)) || 1" :color="v.color"
                                            class="title font-weight-bold">
                                            {{ (summary.checklist?.[k]?.[type] || 0) }}%
                                        </v-progress-circular>
                                        <div class="mt-4">
                                            <div></div>
                                        </div>
                                        <div class="text-left d-flex align-center">
                                            <div class="title mr-2">
                                                {{ (summary.pbc?.[k]?.[type] || 0) }}%
                                            </div>
                                            <div class="line-1" style="font-size: 0.65rem !important">
                                                Information<br />Requests</div>
                                        </div>
                                        <v-progress-linear :value="((summary.pbc?.[k]?.[type] || 0)) || 2"
                                            color="primary lighten-1" height="12"></v-progress-linear>
                                    </div>
                                </v-col>
                            </template>
                            <v-col cols="10" v-if="selected">
                                <div class="radius-on cursor-pointer">
                                    <div class="d-flex align-center mb-2">
                                        <v-btn icon class="mr-2" v-if="tddata.length !=0"
                                            @click="selected = ''"><v-icon>mdi-arrow-left</v-icon></v-btn>
                                        <span class="body-2 font-weight-bold">Tasklist Category wise summary</span>
                                    </div>

                                    <lb-datatableaction :headers="tableheaders" :tabledata=" (summary.checklist?.[selected]?.category_detail || [])"
                                    :itemsperpage="itemsPerPage" searchterm="" :bulkActions="false">

                                    <template v-slot:tbody>
                                        <tr v-for="item in  (summary.checklist?.[selected]?.category_detail || [])" :key="item._id" class=""
                                        :class="{ 'low-opacity': item.status === false }">
                                        <template v-for="header in tableheaders">
                                            <td :key="header.value">
                                                {{item[header.value]}}
                                            </td>
                                        </template>
                                        </tr>
                                    </template>

                                    </lb-datatableaction>
                                 
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="!metadata._id && !loading" class="grey--text title text-center pa-8">
                        No Summary found
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <lb-dialog v-model="showDialogue" width="500">
            <template v-slot:body>
                <div class="">
                    <v-row>
                        <v-col>
                            <div class="text-body-1">Let us get started on setting up a close cycle</div>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <div class="">
                    <v-row>
                        <v-col class="ma-2 pa-2">
                            <!-- <v-btn small @click="$nova.gotoLink({ path: '/financialclose/project/checklist/' +id })">
                      <span>Skip this for now</span>
                    </v-btn> -->
                            <v-btn small color="primary "
                                @click="$nova.gotoLink({ path: '/financialclose/project/trialbalance/' + id })">
                                <span>Get Started</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </lb-dialog>
    </div>
</template>

<script>

export default {
    name: 'financialclose_project_users',
    // props: ['props'],
    data: function () {
        return {
            loading: false,
            metadata: {},
            summary: {},
            color: {},
            keytypes: {},
            selected: "",
            categorydata: [],
            type: "percentcount",
            typetoggle: false,
            LocationsList: [],
            selectedLocation: {},
            selectedUsers:{},
            activitylogconfig: {
                userassociate: {
                    icon: "mdi-plus",
                    color: "info",
                    displaytext: "User Associated",
                },
                userreplaced: {
                    icon: "mdi-account-convert",
                    color: "info",
                    displaytext: "User Replaced",
                },
                userremoved: {
                    icon: "mdi-close",
                    color: "error",
                    displaytext: "User Removed",
                },
                usermoved: { icon: "mdi-sync", color: "", displaytext: "Updated" },
                projectcreate: {
                    icon: "mdi-plus",
                    color: "info",
                    displaytext: "Created",
                },
                projectopen: {
                    icon: "mdi-lock-open-variant",
                    color: "info",
                    displaytext: "Opened",
                },
                projectclose: {
                    icon: "mdi-lock",
                    color: "success",
                    displaytext: "Closed",
                },
                projectupdated: {
                    icon: "mdi-pencil",
                    color: "primary",
                    displaytext: "Updated",
                },
                tbadd: {
                    icon: "mdi-table-plus",
                    color: "error",
                    displaytext: "Trial Balance Added",
                },
                bulkpbcadd: {
                    icon: "mdi-file-question-outline",
                    color: "",
                    displaytext: "Information Request Added",
                },
            },
            isFirst: false,
            showDialogue: false,
            tableheaders:[
                {
                    value: "_id",
                    text: "Category",
                    sortable: false,
                    filterable: false,
                    visible:true,
                },
                {
                    text: "Sub Category",
                    value : "subcategory",
                    sortable: false,
                    filterable: false,
                    visible:true,
                },
                {
                    text: "Pending Submission",
                    value : "pending",
                    sortable: false,
                    filterable: false,
                    visible:true,
                },
                {
                    text: "Pending Approval",
                    value : "awaiting",
                    sortable: false,
                    filterable: false,
                    visible:true,
                },
                {
                    value: "completed",
                    text: "Closed Task",
                    sortable: false,
                    filterable: false,
                    visible:true,
                },
                {
                    
                    value: "total",
                    text: "Total",
                    sortable: false,
                    filterable: false,
                    visible:true,
                }
            ],
            tddata:[],
            userlist:[],
            userdata:[]
        }
    },
    components: {
    },
    created() {
        this.refreshData();
    },
    activated() {
        this.refreshData();
    },
    methods: {
        getNameById(id) {
            let userName = this.userdata.find((location) => location._id === id)
            return userName ? userName.name : ""
        },
        refreshData() {
            this.id = this.$route.params.id || "";
            this.userid = this.$store.state.user.id;
            this.selected = "";
            this.axios.post("/v2/financialclose/analysis/getdetails/" + this.id)
                .then((dt) => {
                    if (dt.data.status === "success") {
                        dt.data.data[0].checklist = dt.data.data[0].checklist || [];
                        // let datas = dt.data.data;
                        // console.log(datas,"datas");
                        this.tddata=dt.data.data[0].tb
                        if(this.tddata.length===0){
                            this.selected='other'
                        }
                        if (dt.data.data[0].checklist.length <= 0 && dt.data.data[0].tb.length <= 0) {
                            this.isFirst = true;
                        }
                        else {
                            this.isFirst = false;
                        }
                    }
                });

            // let colors = this.$nova.getColors(6, 0.5);
            this.keytypes = {
                "income": { name: "Income", color: this.$nova.hexToRgbA("#37AE78", 0.7) }, // green
                "expense": { name: "Expense", color: this.$nova.hexToRgbA("#C69908", 0.7) }, //yellow
                "asset": { name: "Assets", color: this.$nova.hexToRgbA("#4285F4", 0.7) }, //blue
                "liability": { name: "Liabilities", color: this.$nova.hexToRgbA("#db4437", 0.7) },
                "equity": { name: "Equity", color: this.$nova.hexToRgbA("#b242c2", 0.7) },
                "other": { name: "Others", color: this.$nova.hexToRgbA("#6B67CE", 0.7) }, // caccounting
            }
            this.getData();
        },
        getData() {
            this.loading = true;
            this.summary = {};

            return this.axios.post("/v2/financialclose/analysis/getdashboard/" + this.id).then(dt => {
                if (dt.data.status === "success" && dt.data.data.length > 0) {
                    let data = dt.data.data[0] || {};
                    console.log(data, "data");
                    this.metadata = data.metadata || {};
                    this.summary = data.summary || {};
                    this.userlist = [];
                    this.userlist = this.metadata.userlist || [];
                    this.managerlist = [];
                    this.managerlist = this.metadata.managerlist || [];
                    this.stage = this.metadata.stage;
                    this.getLocation();
                }
            }).then(()=>{
                    return this.axios.post("/v2/financialclose/analysis/getusersformdata");
                }).then(dt => {
                if(dt.data.status === "success"){
                    this.userdata=dt.data.data[0].users
                }else throw new Error(dt.data.message);
            }).catch(err => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
            }).finally(() => {
                this.loading = false;
            })
        },
        getLocation() {
            this.loading = true;
            this.axios
                .post("/v2/financialclose/location/get")
                .then((ele) => {
                    if (ele.data.status === "success") {
                        this.LocationsList = ele.data.data || [];
                        this.selectedLocation = {};
                        console.log(this.LocationsList, "LocationsList");
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setUpCloseCycle() {
            this.showDialogue = true;

        },
        filterWithLocation(location) {
            this.selectedLocation = location;
            this.getLocationFilter();
        },
        resetFilter() {
            this.getData();
            this.selectedLocation = {};
        },
        getLocationFilter() {
            this.summary = {};
            this.loading = true;
            this.axios
                .post("/v2/financialclose/analysis/getdashboard/" + this.id + "?location=" + this.selectedLocation._id)
                .then((ele) => {
                    if (ele.data.status === "success") {
                        let data = ele.data.data[0] || {};
                        this.metadata = data.metadata || {};
                        this.summary = data.summary || {};
                        this.userlist = [];
                        this.userlist = this.metadata.userlist || [];
                        this.managerlist = [];
                        this.managerlist = this.metadata.managerlist || [];
                        this.stage = this.metadata.stage;
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        finaluserlist() {
            let nl = [];
            for (const i of this.managerlist) {
                let ud = { ...this.userdata[i] };
                ud.type = "manager";
                nl.push(ud);
            }
            for (const i of this.userlist) {
                let ud = { ...this.userdata[i] };
                ud.type = "general";
                nl.push(ud);
            }
            return nl;
        }
    },
    watch: {
        typetoggle: function (d) {
            if (d) this.type = "percentvalue"
            else this.type = "percentcount"
        }
    }
}
</script>
<style scoped>
.filterlists {
    margin-left: 280px !important;
}
</style>